<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0 justify-content-center auth-bg">
      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">My Munshi</h2>
      </b-link> -->
      <!-- /Brand logo-->

      <b-col
        class="d-flex justify-content-center auth-bg px-2 p-5"
        align-self="center"
      >
        <div>
          <b-col lg="12" class="px-xl-2 mx-auto">
            <b-card>
              <p class="text-center">
                <b-img
                  height="100px"
                  :src="require('@/assets/images/logo/logo_light.png')"
                />
              </p>
              <b-card-title class="mb-1 text-center">
                Forgot Password? 🔒
              </b-card-title>
              <b-card-text class="mb-2 text-center">
                Enter your new password bellow to reset your password.
              </b-card-text>

              <!-- form -->
              <validation-observer ref="simpleRules">
                <b-form
                  class="auth-forgot-password-form mt-2"
                  @submit.prevent="validationForm"
                >
                  <!-- password -->
                  <b-form-group
                    label="New Password"
                    label-for="reset-password-new"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Password"
                      vid="Password"
                      rules="required|password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="reset-password-new"
                          v-model="password"
                          :type="password1FieldType"
                          :state="errors.length > 0 ? false : null"
                          class="form-control-merge"
                          name="reset-password-new"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="password1ToggleIcon"
                            @click="togglePassword1Visibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                  <!-- confirm password -->
                  <b-form-group
                    label-for="reset-password-confirm"
                    label="Confirm Password"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Confirm Password"
                      rules="required|confirmed:Password"
                    >
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid' : null"
                      >
                        <b-form-input
                          id="reset-password-confirm"
                          v-model="cPassword"
                          :type="password2FieldType"
                          class="form-control-merge"
                          :state="errors.length > 0 ? false : null"
                          name="reset-password-confirm"
                          placeholder="············"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            class="cursor-pointer"
                            :icon="password2ToggleIcon"
                            @click="togglePassword2Visibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-overlay
                    :show="$store.state.resetPasswordStore.statusLoading"
                    rounded="sm"
                    variant="transparent"
                    opacity="0.5"
                    blur="2px"
                  >
                    <b-button type="submit" variant="primary" block>
                      Submit
                    </b-button>
                  </b-overlay>
                </b-form>
              </validation-observer>

              <p class="text-center mt-2">
                <b-link
                  @click="
                    $router.replace({
                      name: 'auth-login'
                    })
                  "
                >
                  <feather-icon icon="ChevronLeftIcon" /> Go to login
                </b-link>
              </p>
            </b-card>
          </b-col>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BRow,
  BCol,
  BImg,
  BOverlay
} from 'bootstrap-vue'
import { required } from '@validations'
import store from '@/store/index'

export default {
  components: {
    VuexyLogo,
    BRow,
    BCard,
    BCol,
    BLink,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BOverlay
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      cPassword: '',
      password: '',
      // validation
      required,

      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password'
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          let payload = {
            new_password: this.password,
            new2_password: this.cPassword
          }
          this.$store
            .dispatch('resetPasswordStore/resetPassword', {
              ctx: this,
              payload
            })
            .then((status) => {
              if (status) {
                this.$router.replace({
                  name: 'auth-verify-otp'
                })
              }
            })
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
